export enum CompetitionCMSSteps {
    General = 'general',
    Tournament = 'tournament',
    Registration = 'registration',
    Chapters = 'chapters',
    Details = 'details',
}


export const CompetitionCMSStepsArray = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Tournament,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Details,
];

export const CompetitionCMSCourseStepsArray = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Chapters,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Details,
];
