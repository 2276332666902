import {CategoryUiData} from './category';
import {CompetitionCMSSteps} from '@shared/models/cms';
import {CompetitionRegistration} from '@shared/models/competition-registration';
import {CompetitionStage} from '@shared/models/competition-stage';
import {CompetitionStatistics} from './statistics';
import {CustomElement} from './slateDescendants';
import {EntityUiData} from '@shared/models/entity';
import {Faq} from '@shared/models/faq';
import {FirestoreTimestamp} from '@shared/models/firestore-timestamp';
import {II18n} from '@shared/models/I18n';
import {ImageUris} from '@shared/models/image';
import {ParticipantType} from '@shared/models/participant';
import {Sport} from '@shared/models/sport';
import {Tag} from './tag';
import {TransactionActions} from './wallet';
import {UserUiData} from '@shared/models/user';
import {Video} from '@shared/models/video';

enum CompetitionTemplate {
    LIV_FULL_FORMAT = 'LIV_FULL_FORMAT',
    HIGH_SCORE_MULTI_REGISTRATION = 'HIGH_SCORE_MULTI_REGISTRATION',
    PERFORMANCE_DRILL = 'PERFORMANCE_DRILL',
}

const CompetitionTemplatesArray = Object.values(CompetitionTemplate);

enum CompetitionFormat {
    // Round robin is a group stage where every competitor plays every other competitor once.
    RoundRobin = 'ROUND_ROBIN',
    // Swiss system is a group stage where every competitor plays a fixed number of games against competitors of similar strength.
    SwissSystem = 'SWISS_SYSTEM',
    // Single elimination is a single stage where the winner of each match advances to the next round.
    SingleElimination = 'SINGLE_ELIMINATION',
    // Double elimination is a single stage where the winner of each match advances to the next round.
    DoubleElimination = 'DOUBLE_ELIMINATION',
    // Free for all is a single stage where every competitor plays every other competitor. For example a stroke play match.
    FreeForAll = 'FREE_FOR_ALL',
    // Multi stage is a competition with multiple stages.
    MultiStage = 'MULTI_STAGE',
    // High score is a single stage where the winner is the competitor with the highest score.
    HighScore = 'HIGH_SCORE',
}

const KnockoutFormats = [CompetitionFormat.SingleElimination, CompetitionFormat.DoubleElimination];

enum ChallengeType {
    Competition = 'COMPETITION',
    Course = 'COURSE',
}

enum CompetitionStatus {
    Unpublished = 'UNPUBLISHED',
    Registration = 'REGISTRATION',
    RegistrationEnded = 'REGISTRATION_ENDED',
    Upcoming = 'UPCOMING',
    Ongoing = 'ONGOING',
    Finished = 'FINISHED',
    Cancelled = 'CANCELLED',
}

const PlayableCompetitionStatuses = [CompetitionStatus.Finished, CompetitionStatus.Ongoing, CompetitionStatus.Registration, CompetitionStatus.Upcoming, CompetitionStatus.RegistrationEnded];

const CompetitionStatusesUpcoming = [
    CompetitionStatus.Registration,
    CompetitionStatus.RegistrationEnded,
    CompetitionStatus.Upcoming,
];

interface CompetitionPrize {
    place: 1 | 2 | 3 | number;
    name: II18n;
    description: II18n;
}

interface CompetitionParticipants {
    type: ParticipantType;
    maxCompetition?: number;
    minCompetition?: number;
    maxGroup?: number;
    minGroup?: number;
    total?: number;
    minPerTeam?: number;
}

interface ChallengeDivots {
    actions?: [{
        action: TransactionActions;
        amount: number;
    }]
}

interface CompetitionCategory {
    categoryIds?: string[];
    categories?: {[id:string]:CategoryUiData};
}

interface CompetitionMedia {
    header?: ImageUris;
    banner?: ImageUris;
    featured?: ImageUris;
    logo?: ImageUris;
    video?: Video;
}

interface Competition {
    _geoloc?: {
        lat: number;
        lng: number;
    } | null;
    currentStageId: string;
    currentGroupId?: string;
    currentStage?: number;
    createdAt?: FirestoreTimestamp | Date;

    endsAt: FirestoreTimestamp | Date;
    id: string;
    // Uid is the id of the user who created the competition
    uid?: string;
    // The uids of the users that are organizers of the competition.
    organizerIds?: string[];
    // Organizers:
    organizers?: {[uid: string]: UserUiData};
    name: II18n;
    startsAt: FirestoreTimestamp | Date;
    status: CompetitionStatus;
    stages: {[key: string]: CompetitionStage};

    // Info
    info: {
        description: II18n;
        rules: II18n;
        extraRules?: II18n;
        extraRulesSlate?: {
            slateValue: CustomElement[];
        };
        faq?: Faq;
        prize?: {[place: string] : CompetitionPrize};
        competitionFormat: CompetitionFormat;
        shareLink: string;
        statusInfo?: {[key in CompetitionStatus]?: {
            title: II18n;
            description: II18n;
        }};
        tags?: Tag [];
    }

    // Visuals
    visuals: CompetitionMedia;

    // Branding
    branding?: {
        colors?: {
            primary: string;
        }
    }

    // Fixture
    fixture: {
        sport: Sport;
        ruleIds?: string[];
    }

    // Registrations
    registration: CompetitionRegistration;

    // Participants
    participants: CompetitionParticipants;

    // Community
    community?: {
        entityIds?: string[];
        entities?: {[id:string]:EntityUiData};
    }

    // CMS
    cms?: {
        selectedTemplate?: CompetitionTemplate;
        templateId?: string;
        viewedSteps?: CompetitionCMSSteps[];
    }

    // Statistics
    statistics?: CompetitionStatistics

    challengeType?: ChallengeType;

    divots?: ChallengeDivots;

    category?: CompetitionCategory;

    updatedAt?: FirestoreTimestamp | Date;
}

type UnpublishedCompetition = Pick<Competition, 'id' | 'name' | 'startsAt' | 'status' | 'uid' | 'organizerIds' | 'organizers' | 'createdAt' | 'community'>;

type CompetitionUiData = Pick<Competition, 'id' | 'name' | 'visuals' | 'challengeType' | 'currentGroupId' | 'status' | 'community'>
type CompetitionUiPreviewData = Pick<Competition, 'id' | 'name' | 'visuals' | 'startsAt' | 'endsAt' | 'status' | 'registration' | '_geoloc' | 'challengeType' | 'divots' | 'community' | 'currentGroupId'> & {
    isPreview: boolean;
    ['info.tags']?: Tag[];
    ['info.description']?: II18n;
}

const isFullCompetitionObject = (obj: any): obj is Competition => {
    return (
        obj &&
        obj.participants &&
        obj.stages
        // Add checks for other properties as needed
    );
};


interface LeaveCompetitionRequest {
    registrationId?: string;
    competitionId: string;
}

export type {Competition,
    CompetitionMedia,
    CompetitionUiData, CompetitionUiPreviewData, LeaveCompetitionRequest, CompetitionPrize, UnpublishedCompetition, CompetitionRegistration, CompetitionParticipants};

export {
    ChallengeType,
    CompetitionFormat, CompetitionStatus, KnockoutFormats, CompetitionStatusesUpcoming, CompetitionTemplate, CompetitionTemplatesArray, isFullCompetitionObject,
    PlayableCompetitionStatuses,
};
